import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";

// ------- Styles -------
import { styled } from "@mui/material/styles";

const StyledAppBar = styled(AppBar, {
  name: "StyledBox",
  slot: "Wrapper",
})({
  paddingTop: "20px",
  paddingBottom: "20px",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "primary.dark",
});

const FooterBar = () => {
  return (
    <StyledAppBar className="FooterBar" id="FooterBar" position="static">
      <Container maxWidth="lg">
        <Grid container spacing={0} alignItems="center" justifyContent="center">
          <Grid item>
            <Typography alignItems="center" justifyContent="center" variant="caption">
              Search Exhaust
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </StyledAppBar>
  );
};
export default FooterBar;
